.contact-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Ensure sections stretch to the same height */
  padding: 20px;
  margin-top: 8rem;
  background-color: #fff;
  font-family: Arial, sans-serif;
  gap: 0; /* Ensure no gap between sections */
}

.contact-left {
  width: 40%;
  background-color: #000;
  color: #fff;
  padding: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around; /* Distribute space evenly between items */
  align-items: center; /* Center the content horizontally */
  text-align: center;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.contact-left h1 {
  color: #007bff;
  margin-bottom: 20px;
}

.contact-left p {
  margin-bottom: 20px;
}

.contact-details {
  margin-top: 20px;
  line-height: 1.5;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
}

.contact-item {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.contact-item img {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.contact-item p {
  margin: 0;
  line-height: 1;
}

.contact-form {
  width: 55%;
  background-color: #f9f9f9;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.contact-form label {
  display: block;
  margin-top: 10px;
}

.contact-form input,
.contact-form select,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form .phone-input {
  display: flex;
  justify-content: space-between;
}

.contact-form .phone-input select {
  width: 30%;
  margin-right: 10px;
}

.contact-form .phone-input input {
  width: 68%;
}

.contact-form button {
  width: 100%;
  padding: 10px;
  background: linear-gradient(to right, #5abcf6, #999);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.contact-form button:hover {
  background: linear-gradient(to right, #5abcf6, #888);
}

.error {
  color: red;
  font-size: 12px;
}

@media (max-width: 768px) {
  .contact-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .contact-left,
  .contact-form {
    width: 90%;
    margin-bottom: 20px;
  }
}
