@import url("https://fonts.googleapis.com/css2?family=Helvetica+Neue:wght@300;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ITC+Franklin+Gothic+LT+Book:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ITC+Franklin+Gothic+LT:ital,wght@1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
