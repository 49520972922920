
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header, footer {
  background-color: #f5f5f5;
  padding: 20px;
}

main {
  flex: 1;
}

a {
  color: #00aaff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.header .logo {
  display: flex;
  align-items: center;
}

.header .logo img {
  height: 50px;
  margin-right: 10px;
}

.header .navbar {
  display: flex;
  gap: 15px;
}

.header .navbar a {
  font-weight: bold;
}

/* Footer Styles */
.footer {
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
  border-top: 1px solid #ddd;
}

.footer .footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer a {
  color: #00aaff;
}

.footer a:hover {
  text-decoration: underline;
}

/* Page Specific Styles */
.home, .contact-us, .services, .careers, .about-us {
  padding: 50px 0;
  text-align: center;
}

.home h1, .contact-us h1, .services h1, .careers h1, .about-us h1 {
  color: #00aaff;
  margin-bottom: 20px;
}

.home p, .contact-us p, .services p, .careers p, .about-us p {
  color: #555;
}
